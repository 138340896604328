.navigation-wrapper {
  position: relative;
}
.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: hsl(43, 74%, 49%, 1);
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active.dark {
  background: #a6a9be;
}
.dot.active.light {
  background: #000;
}

/* .arrow {
  width: 25px;
  height: 30px;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 40%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%); */

/* // fill: hsl(43, 74%, 49%, 1); */
/* fill: rgb(9, 7, 1); */

/* // background-color: hsl(43, 74%, 49%, 0.2); */
/* background-color: hsl(43, 74%, 49%, 0.2);
  
  cursor: pointer;
  margin: 0;
  padding: 0;
} */

/* 
.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}  */

/* .arrow--disabled { */
/* // fill: rgba(255, 255, 255, 0.5); */
/* // fill: hsl(43, 74%, 49%, 1); */
/* fill: rgb(177,177,177); */
/* } */

/* 
.keen-slider:not([data-keen-slider-disabled]) {
  align-content: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}
.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
  flex-direction: row-reverse;
}
.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
  flex-wrap: wrap;
} */
/* 
.keen-slider:not([data-keen-slider-disabled]){
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color:transparent;
  align-content:flex-start;
  display:flex;
  overflow:hidden;
  position:relative;
  touch-action:pan-y;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -khtml-user-select:none;
  width:100%
}
.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide { 
  min-height:100%;
  overflow:hidden;
  position:relative;
  width:100%;
}
.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
  flex-direction:row-reverse
}
.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] { 
  flex-wrap:wrap
} */
